import { AddIcon, EmailIcon, PhoneIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, FormControl, FormLabel, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Text } from "@chakra-ui/react"
import { map, orderBy } from "lodash"
import React, { useEffect, useState } from "react"
import { CiBank } from "react-icons/ci";
import { useContactStore } from "../../store/Contact";
import { STATUS } from "../../Constant";
import { DeleteButton } from "../../Constant/DeleteButton";
import { BankAccount } from "./BankAccount";
import { UpiAccount } from "./UpiAccount";
import { VerifyOTP } from "./VerifyOTP";
import { useZwitchStore } from "../../store/Zwitch";

export const FundAccount = ({ contact, closeModal }) => {
    const [addFund, setAddFund] = useState(null)
    const [inputValue, setInputValue] = useState({})
    const [toggleAmount, setToggleAmount] = useState(null)
    const [toggleVerifyOtp, setToggleVerifyOtp] = useState(null)

    const { getContactBanks, contactBanksStatus, contactBanks,
        addBankStatus, addUpiStatus,
        deleteBankAction, deleteBankStatus,
        deleteUpiAction, deleteUpiStatus,
        deleteAllFundsAction, deleteAllFundsStatus,
        resetAddStatus, resetDeleteStatus
    } = useContactStore(s => ({
        getContactBanks: s.getContactBanks,
        contactBanksStatus: s.contactBanksStatus,
        contactBanks: s.contactBanks,
        addBankStatus: s.addBankStatus,
        addUpiStatus: s.addUpiStatus,
        deleteBankAction: s.deleteBankAction,
        deleteBankStatus: s.deleteBankStatus,
        deleteUpiAction: s.deleteUpiAction,
        deleteUpiStatus: s.deleteUpiStatus,
        deleteAllFundsAction: s.deleteAllFundsAction,
        deleteAllFundsStatus: s.deleteAllFundsStatus,
        resetAddStatus: s.resetAddStatus,
        resetDeleteStatus: s.resetDeleteStatus
    }))

    const { verifyReferenceAction, verifyReferenceStatus, resetReferenceStatus } = useZwitchStore(s => ({
        verifyReferenceAction: s.verifyReferenceAction,
        verifyReferenceStatus: s.verifyReferenceStatus,
        resetReferenceStatus: s.resetReferenceStatus
    }))

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }

    useEffect(() => {
        if (contact.id) {
            getContactBanks(contact.id)
        }
    }, [contact, getContactBanks])

    const handleToggle = (bank) => {
        setInputValue({})
        setToggleAmount(bank)
    }

    const fundTransfer = () => {
        verifyReferenceAction({ referenceId: inputValue?.referenceId })
    }

    useEffect(() => {
        if (verifyReferenceStatus === STATUS.SUCCESS) {
            resetReferenceStatus()
            setToggleVerifyOtp({
                contactId: contact.id,
                amount: inputValue?.amount,
                referenceId: inputValue?.referenceId,
                via: inputValue?.via
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact.id, verifyReferenceStatus])


    useEffect(() => {
        if (addUpiStatus === STATUS.SUCCESS || addBankStatus === STATUS.SUCCESS) {
            resetAddStatus()
            setAddFund(false)
        }
    }, [addBankStatus, addUpiStatus, resetAddStatus])

    const deleteBank = (id) => {
        deleteBankAction(id)
    }

    const deleteUpi = (id) => {
        deleteUpiAction(id)
    }

    const deleteAllBanks = (id) => {
        deleteAllFundsAction(id)
    }

    return (
        <Modal isOpen={contact} onClose={closeModal} size="2xl">
            <ModalOverlay />
            <ModalContent>
                {/* <ModalHeader borderBottom={"1px solid"} borderColor={"gray.200"}>
                    <Flex borderRadius={10} align={"center"}>
                        <Box>
                            <Flex align="center">
                                <Text fontSize={16} fontWeight={"semibold"} color={"gray.900"}>{contact.name}</Text>
                                <Tag ml={10} size={"sm"} fontSize={10}>{contact?.type || "Customer"}</Tag>
                            </Flex>
                            <Flex mt={2} fontSize={12} color={"gray.500"}>
                                <Flex align={"center"}>
                                    <PhoneIcon />
                                    <Text ml={2}>{contact.mobileNo}</Text>
                                </Flex>
                                <Flex align={"center"} ml={10}>
                                    <EmailIcon />
                                    <Text ml={2}>{contact.email}</Text>
                                </Flex>
                            </Flex>
                        </Box>
                    </Flex>
                </ModalHeader> */}
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    {addFund ?
                        <Flex my={5} w={"100%"} justify={"space-between"}>
                            <Text fontSize={12} fontWeight={"semibold"}>Account Type</Text>
                            <Button size={"xs"} colorScheme="blue" leftIcon={<AddIcon />} variant={"outline"} onClick={() => setAddFund(false)}>Don't Know Fund Account?</Button>
                        </Flex>
                        :
                        toggleVerifyOtp ?
                            null
                            :
                            <Flex my={5} w={"100%"} justify={"space-between"}>
                                <Text fontSize={12} fontWeight={"semibold"}>Pay to</Text>
                                <Button size={"xs"} colorScheme="blue" leftIcon={<AddIcon />} variant={"outline"} onClick={() => setAddFund(true)}>Fund Account</Button>
                            </Flex>
                    }
                    {addFund ?
                        null
                        :
                        (contactBanks?.bank?.length || contactBanks?.upi?.length) && !toggleVerifyOtp ?
                            <Flex w={"100%"} justify={"flex-end"}>
                                <DeleteButton
                                    description={"Are you sure? Do you want to delete all fund Accounts?"}
                                    confirm={() => deleteAllBanks(contact.id)}
                                    status={deleteAllFundsStatus}
                                    reset={resetDeleteStatus}
                                    label={"Delete All Fund Accounts"}
                                />
                            </Flex>
                            :
                            null
                    }
                    {addFund ?
                        <Tabs isFitted isLazy onChange={() => setInputValue({})}>
                            <TabList>
                                <Tab>UPI</Tab>
                                <Tab>Bank A/c</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <UpiAccount contact={contact} setAddFund={setAddFund} />
                                </TabPanel>
                                <TabPanel>
                                    <BankAccount contact={contact} setAddFund={setAddFund} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        :
                        <Box>
                            {contactBanksStatus === STATUS.FETCHING ?
                                <Flex justify={"center"} w={"100%"}>
                                    <Spinner />
                                </Flex>
                                :
                                toggleVerifyOtp ?
                                    <VerifyOTP bank={toggleAmount} data={toggleVerifyOtp} closeModal={closeModal} handleToggle={handleToggle} setToggleVerifyOtp={setToggleVerifyOtp} />
                                    :
                                    (contactBanks?.bank?.length || contactBanks?.upi?.length) ?
                                        <Box>
                                            {map(orderBy([...contactBanks?.bank, ...contactBanks?.upi], "createdAt", "desc"), bank => (
                                                bank?.accountNo ?
                                                    <Box
                                                        p={2}
                                                        mt={1}
                                                        borderRadius={10}
                                                        cursor={"pointer"}
                                                        _hover={{ bg: "cardDark", color: "defaultWhite" }}
                                                        bg={bank?.accountNo === toggleAmount?.accountNo ? "cardDark" : "transparent"}
                                                        color={bank?.accountNo === toggleAmount?.accountNo ? "defaultWhite" : "black"}
                                                    >
                                                        <Box>
                                                            <Flex justify={"space-between"} align={"center"} onClick={() => handleToggle(bank)}>
                                                                <Flex w={"90%"} gap={4} align={"center"}>
                                                                    <Flex p={2} bg={"gray.100"} borderRadius={"50%"} color={"gray.800"}><CiBank /></Flex>
                                                                    <Text display={{ base: "none", md: "initial" }} fontSize={13} fontWeight={"semibold"}>{bank.beneficiaryName}</Text>
                                                                    <Text display={{ base: "none", md: "initial" }} fontSize={11} color={"gray.500"}>Acc No. {bank.accountNo}</Text>
                                                                    <Flex display={{ base: "initial", md: "none" }} w={"70%"} justify={"space-between"}>
                                                                        <Text fontSize={12} fontWeight={"semibold"}>{bank.beneficiaryName}</Text>
                                                                        <Flex fontSize={10} color={"gray.500"}>
                                                                            <Text>Acc No. </Text>
                                                                            <Text ml={1}>{bank.accountNo}</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                                <DeleteButton
                                                                    description={"Are you sure? Do you want to delete bank?"}
                                                                    confirm={() => deleteBank(bank.id)}
                                                                    status={deleteBankStatus}
                                                                    reset={resetDeleteStatus}
                                                                />
                                                            </Flex>
                                                            {bank?.accountNo === toggleAmount?.accountNo ?
                                                                <Flex py={2} flexDir={"column"} gap={2}>
                                                                    <InputForm w={"30%"} label={"Amount"} type={"number"} limit={14} name={"amount"} focus={true} inputValue={bank?.accountNo === toggleAmount?.accountNo ? inputValue : {}} setInputValue={setInputValue} />
                                                                    <Flex gap={2}>
                                                                        <InputForm w={"75%"} label={"Reference Id"} type={"text"} name={"referenceId"} inputValue={bank?.accountNo === toggleAmount?.accountNo ? inputValue : {}} setInputValue={setInputValue} />
                                                                        <Select w={"35%"} size={"sm"} variant={"flushed"} color={"white"} fontWeight={"semibold"} value={bank?.accountNo === toggleAmount?.accountNo ? inputValue.via : {}} borderColor={"gray.400"} onChange={(e) => inputHandler("via", e.target.value)}>
                                                                            <option style={{ color: "black" }} value={""}>Select Via</option>
                                                                            <option style={{ color: "black" }} value={"Paxful"}>Paxful</option>
                                                                            <option style={{ color: "black" }} value={"Noones"}>Noones</option>
                                                                            <option style={{ color: "black" }} value={"Offline"}>Offline</option>
                                                                            <option style={{ color: "black" }} value={"Lcs"}>Lcs</option>
                                                                        </Select>
                                                                    </Flex>
                                                                </Flex>
                                                                :
                                                                null
                                                            }
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Box
                                                        p={2}
                                                        mt={1}
                                                        borderRadius={10}
                                                        cursor={"pointer"}
                                                        _hover={{ bg: "cardDark", color: "defaultWhite" }}
                                                        bg={bank?.upiId === toggleAmount?.upiId ? "cardDark" : "transparent"}
                                                        color={bank?.upiId === toggleAmount?.upiId ? "defaultWhite" : "black"}
                                                    >
                                                        <Box style={bank?.upiId === toggleAmount?.upiId ? { bg: "cardDark", color: "defaultWhite" } : null}>
                                                            <Flex justify={"space-between"} align={"center"} onClick={() => handleToggle(bank)}>
                                                                <Flex gap={4} align={"center"}>
                                                                    <Flex p={2} bg={"gray.100"} borderRadius={"50%"}>
                                                                        <Image h={"16px"} w={"16px"} src={"/assets/upi.png"} />
                                                                    </Flex>
                                                                    <Text fontSize={12} fontWeight={"semibold"}>UPI</Text>
                                                                    <Text fontSize={10} color={"gray.500"}>{bank.upiId}</Text>
                                                                </Flex>
                                                                <DeleteButton
                                                                    description={"Are you sure? Do you want to delete upi?"}
                                                                    confirm={() => deleteUpi(bank.id)}
                                                                    status={deleteUpiStatus}
                                                                    reset={resetDeleteStatus}
                                                                />
                                                            </Flex>
                                                            {bank?.upiId === toggleAmount?.upiId ?
                                                                <Flex py={2} flexDir={"column"} gap={2}>
                                                                    <InputForm w={"30%"} label={"Amount"} type={"number"} limit={14} name={"amount"} focus={true} inputValue={bank?.upiId === toggleAmount?.upiId ? inputValue : {}} setInputValue={setInputValue} />
                                                                    <Flex gap={2}>
                                                                        <InputForm w={"75%"} label={"Reference Id"} type={"text"} name={"referenceId"} inputValue={bank?.upiId === toggleAmount?.upiId ? inputValue : {}} setInputValue={setInputValue} />
                                                                        <Select w={"35%"} size={"sm"} variant={"flushed"} color={"white"} fontWeight={"semibold"} value={bank?.upiId === toggleAmount?.upiId ? inputValue?.via : {}} borderColor={"gray.400"} onChange={(e) => inputHandler("via", e.target.value)}>
                                                                            <option style={{ color: "black" }} value={""}>Select Via</option>
                                                                            <option style={{ color: "black" }} value={"Paxful"}>Paxful</option>
                                                                            <option style={{ color: "black" }} value={"Noones"}>Noones</option>
                                                                            <option style={{ color: "black" }} value={"Offline"}>Offline</option>
                                                                            <option style={{ color: "black" }} value={"Lcs"}>Lcs</option>
                                                                        </Select>
                                                                    </Flex>
                                                                </Flex>
                                                                :
                                                                null
                                                            }
                                                        </Box>
                                                    </Box>
                                            ))}
                                            <Flex w={"100%"} mt={5} justifyContent={"flex-end"}>
                                                {/* <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={() => setAddFund(false)}>Back</Button> */}
                                                <Button size={"sm"} colorScheme={"blue"} isDisabled={inputValue?.amount ? false : true} isLoading={verifyReferenceStatus === STATUS.FETCHING} onClick={() => fundTransfer(inputValue)} >Next</Button>
                                            </Flex>
                                        </Box>
                                        :
                                        <Flex py={8} w={"100%"} justify={"center"} fontSize={14} fontWeight={"semibold"}>Add Fund Account</Flex>
                            }
                        </Box>
                    }
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}

const InputForm = ({ name, label, limit, type, inputValue, setInputValue, w, focus }) => {

    const inputHandler = (val) => {
        if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired>
            <Flex align={"center"}>
                <FormLabel w={w || "65%"} fontSize={13}>{label}</FormLabel>
                <Input size={"sm"} autoFocus={focus} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} onWheel={(e) => e.target.blur()} borderColor={"gray.400"} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}